<template>
    <div class="progress-wrapper">
        <div class="progress">
            <div
                class="progress-bar progress-bar-striped progress-bar-animated"
                :style="`background-color: ${bgColor};width: ${currentProgress}%`"
                role="progressbar"
                :aria-valuenow="currentValue"
                :aria-valuemin="minValue"
                :aria-valuemax="maxValue"
            >
                <div
                    class="label"
                    :style="`color: ${fontColor};font-weight: ${fontWeight}`"
                >
                    {{ currentValue }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        currentValue: {
            type: Number,
            default: () => 100
        },

        maxValue: {
            type: Number,
            default: () => 100
        },

        minValue: {
            type: Number,
            default: () => 0
        },

        bgColor: {
            type: String,
            default: () => '#15BDC7'
        },

        fontColor: {
            type: String,
            default: () => 'black'
        },

        fontWeight: {
            type: String,
            default: () => '400'
        }
    },

    computed: {
        currentProgress() {
            return (this.currentValue - this.minValue) / (this.maxValue - this.minValue) * 100;
        }
    }
}
</script>

<style scoped>
.progress-wrapper {
    height: 60px;
}

.progress {
    overflow: visible;
}

.progress-bar {
    position: relative;
    border-radius: inherit;
    overflow: visible;
}

.label {
    position: absolute;
    right: 0px;
    background-color: inherit;
    padding: 15px;
    transform: translateX(50%) translateY(100%);
}

.label::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 50%;
    width: 10px;
    height: 10px;
    background-color: inherit;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
</style>